import { VStack } from "@chakra-ui/react"
import * as React from "react"
import { useNavigate } from "react-router-dom"
import { Choices } from "../components/Choices"
import { Header } from "../components/Header"
import { Paragraph } from "../components/Paragraph"
import WholeData from '../novel/data.json'

export const Home: React.FC = () => {
  const navigate = useNavigate()
  const data = WholeData.find((item) => item.id==="home")
  
  const [done,setDone] = React.useState<Boolean>(false)
  
  React.useEffect(()=> {
      setDone(false)
  },[])
  if (data === undefined) return <></>
  return (
    <>
            <Header />
            <VStack margin='auto' spacing={8} width="500px"
                marginTop="10" minWidth="500px" fontSize="xl" alignItems="left" >
                <Paragraph tag="strong" speed={40} paragraph={data.content} onDone={()=>{setDone(true)}}/>
                {done &&
                    <Choices choices={data.choices} navigate={(path:string) => {navigate(path);window.location.reload()}} />
                }
            </VStack>
        </>
  )
}