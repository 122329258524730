import React from "react";

import { TypingMultiline } from "react-kr-typing-anim";

type ParagraphProps = {
    paragraph: string[]
    speed?: number
    tag?: "div" | "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p" | "span" | "strong"
    onDone?: (()=>void)
}

export const Paragraph: React.FC<ParagraphProps> = (props: ParagraphProps) => {
    const { paragraph, speed, tag } = props
    return (
        <TypingMultiline speed={speed===undefined ? 90:speed}
         Tag={tag===undefined ? "p":tag} cursor onDone={props.onDone}>
            {paragraph}
        </TypingMultiline>
    )
}