import { chakra, Container, Flex, useColorModeValue } from "@chakra-ui/react";
import React from "react";
import { ColorModeSwitcher } from "../ColorModeSwitcher";

export const Header: React.FC = () => {
    const bg = useColorModeValue("white", "gray.900")

    return (
        <chakra.header
            bg={bg}
            w="full"
            shadow='md'
        >  
            <Container w="80%" py={4}>
                <Flex alignItems="center" justifyContent="space-between" mx="initial">
                    <Flex>
                        <chakra.h1
                            fontSize="xl" fontWeight='bold'
                        >
                            Writing
                        </chakra.h1>
                    </Flex>
                    <ColorModeSwitcher />
                </Flex>
            </Container>
        </chakra.header>
    )
}