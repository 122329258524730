import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

export const Station = () => {
    const params = useParams()
    const navigate = useNavigate()
    useEffect(() => {
        navigate('/novel/'+params.id)
    })
    return <></>
}