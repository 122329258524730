import { ChakraProvider } from '@chakra-ui/react';
import * as React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Home } from "./pages/Home";
import { Novel } from './pages/Novel';
import { Station } from './pages/Station';

ReactDOM.render(
  <ChakraProvider>
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/novel/:id' element={<Novel />} />
        <Route path='/station/:id' element={<Station />} />
      </Routes>
    </BrowserRouter>
  </ChakraProvider>,
  document.getElementById("root"),
)
