import { Image, VStack } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Choices } from "../components/Choices";
import { Header } from "../components/Header";
import { Paragraph } from "../components/Paragraph";
import WholeData from '../novel/data.json';

type Props = {
    speed?: number
}

export const Novel: React.FC<Props> = ({speed}: Props) => {
    const params = useParams()
    const navigate = useNavigate()
    const data = WholeData.find((item) => item.id===params.id)
    const ismap: boolean = params.id === '7_1'
    const [done,setDone] = useState<Boolean>(false)
    
    useEffect(()=> {
        setDone(false)
    },[])

    if (params.id === undefined) return <></>
    if (data === undefined) return <></>

    return (
        <>
            <Header />
            <VStack margin='auto' spacing={8} width="500px"
                marginTop="10" minWidth="500px" fontSize="xl" alignItems="left" >
                {ismap &&
                    <Image src={'https://mir-s3-cdn-cf.behance.net/project_modules/max_1200/4692e9108512257.5fbf40ee3888a.jpg'} />
                }
                <Paragraph tag="strong" speed={speed === undefined ? 20 : speed} paragraph={data.content} onDone={()=>{setDone(true)}}/>
                {done &&
                    <Choices choices={data.choices} navigate={(path:string) => {;navigate(path);window.location.reload()}} />
                }
            </VStack>
        </>
    )
}